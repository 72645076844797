<template lang="pug">
  v-container
    v-row
      v-col(cols="3")
        v-row
          v-col(cols="12")
            v-toolbar(
              color="primary"
              dark
              dense
              flat
            )
              span New Purchase Requisition
              v-spacer
              v-icon mdi-account
              v-icon mdi-plus
            v-alert(
              class="mt-2"
              type="error"
              dismissible
              v-for="(item, index) in purchReqPostErrors"
              :key="index"
            )
              span(class="text-uppercase") {{ item.first() }}
          v-col(
            cols="6"
          )
            v-text-field(
              label="PR ID"
              :value="id"
              disabled
            )
          v-col(
            cols="6"
          )
            v-text-field(
              label="Status"
              value="New"
              disabled
            )
          v-col(
            cols="6"
          )
            v-text-field(
              label="Date Submitted"
              v-model="submittedDate"
              :error-messages="purchaseRequestStorePostErrors.submitted_date"
              type="date"
            )
          v-col(
            cols="6"
          )
            v-text-field(
              label="Date Expected"
              v-model="expectedDate"
              :error-messages="purchaseRequestStorePostErrors.expected_date"
              type="date"
            )
          v-col(
            cols="12"
          )
            v-text-field(
              label="Amount"
              :value="amount"
            )
          v-col(
            cols="12"
          )
            v-textarea(
              label="Notes"
              v-model="notes"
              outlined
            )
          v-col(
            cols="12"
          )
            v-btn(
              color="primary"
              dark
              @click="submitData"
              :loading="purchaseRequestStorePosting"
              block
            )
              span Save
          v-col(
            cols="12"
          )
            v-btn(
              color="red"
              dark
              block
              @click="$router.push('/purchase-request')"
            )
              span Cancel
      v-col(cols="9")
        v-toolbar(
          color="primary"
          dense
          flat
          dark
        )
          span Detail
        create-details(
          v-model="details"
        )
</template>
<script>
import purchaseRequestRepository from '@/repositories/purchase-request.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [purchaseRequestStoreVars, purchaseRequestStoreVarNames] = requestVars({ identifier: 'purchase-request-store', hasData: false, request: 'post' })

const purchaseRequestStoreHandler = new VueRequestHandler(null, purchaseRequestStoreVarNames)

const inputVars = () => ({
  id: null,
  submittedDate: Window.getCurrentDate(),
  expectedDate: Window.getCurrentDate().addDays(1),
  notes: null,
  details: [],
})

export default {
  name: 'CreatePurchaseRequest',
  components: {
    createDetails: () => import('./details/Index'),
  },
  created () {
  },
  data () {
    return {
      ...inputVars(),
      ...purchaseRequestStoreVars,
    }
  },
  computed: {
    amount () {
      return 1
    },
  },
  methods: {
    submitData () {
      const handler = purchaseRequestStoreHandler
      const repository = purchaseRequestRepository.store
      const { id, ...data } = this.getInputData()
      handler.setVue(this)
      handler.execute(repository, [data])
    },
    getInputData () {
      return Object.keys(inputVars())
        .reduce((result, key) => {
          const camelKey = key.camelToSnakeCase()
          if (key === 'details') {
            if (!this[key].length) return result
            result[key] = this[key].map(item => Window.objectChangeKeyCase(item, 'camelToSnakeCase'))
            return result
          }
          result[camelKey] = this[key]
          return result
        }, {})
    },
  },
}
</script>
